import React from 'react';
import styles from './CardsDesigns.module.css';
import CardItemDesigns from './CardItemDesigns';

function CardsDesigns() {
  return (
      <div className={styles.cards}>
        {/* <h1>Complete Web Design & Hosting Solutions</h1> */}
        <div className={styles.cards__container}>
          <div className={styles.cards__wrapper}>
            <ul className={styles.cards__items}>
              <CardItemDesigns
                src='images/comingsoon.jpg'
                text='Salt n Sacrifice'
                label='Design'
                path=''
              />
              <CardItemDesigns
                src='images/comingsoon.jpg'
                text='Brock Jennings Music'
                label='Hosting'
                path=''
              />
            </ul>
          </div>
        </div>
      </div>
  );
}

export default CardsDesigns;