import React from 'react'
import './ReviewsQuotes.css'

function ReviewsQuotes() {
    return (
    <>
        <div class='container'>
        <blockquote class="q-card q-card-color-1">
        <div class="content">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh eusismod tincidunt.</div>
        <div class='author'>-John Doe</div>
        </blockquote>
        </div>

        <div class='container'>
        <blockquote class="q-card q-card-color-2">
        <div class="content">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh eusismod tincidunt.</div>
        <div class='author'>-John Doe</div>
        </blockquote>
        </div>

        <div class='container'>
        <blockquote class="q-card q-card-color-3">
        <div class="content">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh eusismod tincidunt.</div>
        <div class='author'>-John Doe</div>
        </blockquote>
        </div>
    </>
)
}

export default ReviewsQuotes