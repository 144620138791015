import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Designs from './components/pages/Designs';
import Reviews from './components/pages/Reviews';
import Contact from './components/pages/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={ <Home /> }/>
          <Route path='/services' element={ <Services /> }/>
          <Route path='/Designs' element={ <Designs /> }/>
          <Route path='/Reviews' element={ <Reviews /> }/>
          <Route path='/contact' element={ <Contact /> }/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
