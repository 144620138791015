import React from 'react'
import '../../App.css'
import ReviewsQuotes from '../ReviewsQuotes';


export default function Reviews() {
    return(
    <>
        <h1 className='reviews'>REVIEWS</h1>
        <ReviewsQuotes />
    </>
    );
}