import React from 'react'
import '../App.css'
import { Button } from './Button'
import './HeroSection.css'

function HeroSection() {
  return (
    <div className="hero-container">
        <video src="./videos/ocean-vid-1.mp4" autoPlay loop muted />
        <h1>805 WEB DESIGN</h1>
        <p>Professional web design at an affordable price</p>
        <div className="hero-btns">
            <Button className="btns" buttonStyle="btn--outline" 
            buttonSize="btn--large" to="/services">SERVICES</Button>
            <Button className="btns" buttonStyle="btn--primary" 
            buttonSize="btn--large" to="contact">FREE CONSULT<i class="fa-regular fa-handshake" id="consult-icon"></i></Button>
        </div>
    </div>
  )
}

export default HeroSection