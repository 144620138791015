import React from 'react';
import styles from './CardsServices.module.css';
import CardItemServices from './CardItemServices';

function CardsServices() {
  return (
    <div className={styles.cards}>
      <h1>Everything Your Business Needs</h1>
      <div className={styles.cards__container}>
        <div className={styles.cards__wrapper}>
          <ul className={styles.cards__items}>
            <CardItemServices
              src='images/design.png'
              title='Design'
              // label='Design'
              text1="High-quality web designs."
              text2="Responsive for all devices."
              text3="Customized to your needs."
              text4="Built with the latest technologies."
              text5="Designed with you every step of the way."
              path='/contact'
            />
            <CardItemServices
              src='images/hosting.jpg'
              title='Hosting'
              // label='Hosting'
              text1="Professional hosting solutions."
              text2="Secure and reliable."
              text3="Fast and efficient."
              path='/contact'
            />
            <CardItemServices
              src='images/seo.jpg'
              title='Search Engine Optimization'
              // label='SEO'
              text1="SEO services."
              text2="Increase your online visibility."
              text3="Attract more customers."
              text4="Grow your business."
              path='/contact'
            />
            <CardItemServices
              src='images/support.png'
              title='Support'
              // label='Support'
              text1="24/7 support."
              text2="Fast and efficient."
              text3="Friendly and professional."
              path='/contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsServices;