import React from 'react'
import '../../App.css'
import CardsServices from '../CardsServices';

export default function Services() {
    return( 
        <>
        <h1 className='services'>SERVICES</h1>
        <CardsServices />
        </>
    );
}