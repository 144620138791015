import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from './ContactForm.module.css';

export const ContactForm = () => {
  const formik = useFormik({
    initialValues:{
        name:"",
        email:"",
        phone:"",
        message:"",
    },
    // Yup integrates with formik to handle form validation
    validationSchema: Yup.object({
        name: Yup.string().max(30, "Name must be 30 characters or less").required("Name is required"),
        email: Yup.string().email("Invalid email address").required("Email is required"),
        phone: Yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, "Invalid phone number format").required("Phone number is required"),
        message: Yup.string().min(10, "Message must be at least 10 characters long").required("Message is required"),
    }),
    onSubmit: (values, { resetForm }) => {
        alert(JSON.stringify(values, null, 2)); // Alert the form values for testing
        sendEmail();
        resetForm();
    }
  });

  const form = useRef();

  const sendEmail = () => {
    emailjs.sendForm("service_2bk3sai", "template_ogppby4", form.current, "MIrjjsJMl8XE9LcXb")
      .then((result) => {
        console.log('Email sent successfully!');
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form ref={form} onSubmit={formik.handleSubmit}>
          <label htmlFor="user_name" className={`${formik.touched.name && formik.errors.name ? "text-red-500" : ""}`}>
            Name
          </label>
          <input
            type="text"
            name="name"
            id="user_name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className={styles.error}>{formik.errors.name}</div>
          ) : null}

          <label htmlFor="email" className={`${formik.touched.email && formik.errors.email ? "text-red-500" : ""}`}>
            Email
          </label>
          <input
            type="email"
            name="email"
            id="user_email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={styles.error}>{formik.errors.email}</div>
          ) : null}

          <label htmlFor="phone" className={`${formik.touched.phone && formik.errors.phone ? "text-red-500" : ""}`}>
            Phone Number
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder="555-555-5555"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className={styles.error}>{formik.errors.phone}</div>
          ) : null}

          <label htmlFor="message" className={`${formik.touched.message && formik.errors.message ? "text-red-500" : ""}`}>
            Message
          </label>
          <textarea
            name="message"
            id="message"
            placeholder="Send us your message here!"
            className={styles.message}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message ? (
            <div className={styles.error}>{formik.errors.message}</div>
          ) : null}

          <button type="submit" className={styles.formButton}>SUBMIT</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
