import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CardsServices.module.css'

function CardItemServices(props) {
  return (
    <>
      <li className={styles.cards__item}>
        <Link className={styles.cards__item__link} to={props.path}>
          <figure className={styles.cards__item__pic_wrap} data-category={props.label}>
            <img
              className={styles.cards__item__img}
              alt='Card Image'
              src={props.src}
            />
          </figure>
          <div className={styles.cards__item__info}>
            <h5 className={styles.cards__item__text}>{props.title}</h5>
            <ul className={styles.cards__item__list}>
              {props.text1 && <li className={styles.cards__item__list__item}>{props.text1}</li>}
              {props.text2 && <li className={styles.cards__item__list__item}>{props.text2}</li>}
              {props.text3 && <li className={styles.cards__item__list__item}>{props.text3}</li>}
              {props.text4 && <li className={styles.cards__item__list__item}>{props.text4}</li>}
              {props.text5 && <li className={styles.cards__item__list__item}>{props.text5}</li>}
            </ul>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItemServices;