import React from 'react'
import '../../App.css'
import CardsDesigns from '../CardsDesigns';

export default function Designs() {
    return(
    <>
        <h1 className='designs'>DESIGNS</h1>
        <CardsDesigns />
    </>
    );
}