import React from 'react'
import '../../App.css'
import ContactForm from '../ContactForm'

export default function Contact() {
    return(
    <>
        <h1 className='contact'>CONTACT</h1>
        <ContactForm />
    </>
    );
}