import React from 'react';
import styles from './Cards.module.css';
import CardItem from './CardItem';

function Cards() {
  return (
      <div className={styles.cards}>
        <h1>Complete Web Design & Hosting Solutions</h1>
        <div className={styles.cards__container}>
          <div className={styles.cards__wrapper}>
            <ul className={styles.cards__items}>
              <CardItem
                src='images/design.png'
                text='Website Designs Utilizing the Latest Technologies'
                label='Design'
                path='/contact'
              />
              <CardItem
                src='images/hosting.jpg'
                text='Affordable Web Hosting Solutions'
                label='Hosting'
                path='/contact'
              />
            </ul>
            <ul className={styles.cards__items}>
              <CardItem
                src='images/seo.jpg'
                text='Search Engine Optimization'
                label='SEO'
                path='/contact'
              />
              <CardItem
                src='images/satisfaction.png'
                text='100% Satisfaction Guarantee'
                label='Guarantee'
                path='/contact'
              />
              <CardItem
                src='images/support.png'
                text='24/7 Customer Support'
                label='Support'
                path='/contact'
              />
            </ul>
          </div>
        </div>
      </div>
  );
}

export default Cards;